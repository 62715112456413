<template>
  <main>
    <div style="color: black;" class="basic-page provability-explained-page">
      <h1 style="color: black;">
        Provability Explained
      </h1>
      <h2 style="color: black;">Provably Fair Roulette</h2>
      <p>Most BGaming games you can play here at bitleon are provably fair and verifiable immediately and independently
        by you!</p>
      <p>We use a cryptographic method that ensures that we (the casino) or you (the player) can't possibly know the
        outcome of the game until the moment it starts. We do this by letting you have a simple and visually verifiable
        input on the game and we show you a fingerprint of the spin we are going to use before the game starts so you can
        check after the game is over that we used the spin we said we would. Here's how it works:</p>
      <ol>
        <li>
          <h3 style="color: black;">We use a computer model for the Roulette Wheel and decide on a random spin to apply to it.</h3>
          <p>We model the roulette wheel as a list of the tiles in the order they appear and then generate a random
            location for the roulette wheel to stop at. We use the best pseudorandom number generator out there, the <a
              href="https://en.wikipedia.org/wiki/Mersenne_Twister" id="twister" rel="nofollow">Mersenne Twister</a>
            algorithm. We call the spin 'Result'.</p><img
            src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-1.2d5fddd.jpg" alt="image-1">
        </li>
        <li>
          <h3 style="color: black;">We show you a 'fingerprint' of the spin and a random number we call 'Secret'.</h3>
          <p>We call this Hash (Result+Secret). We generate a random number called 'secret' and use this to create a
            fingerprint of the data to model the game. This ensures that you cannot figure out the final result of the
            spin from just the fingerprint. We create this unique finger print by hashing the data with the <a
              href="https://en.wikipedia.org/wiki/SHA-2" id="sha" rel="nofollow">SHA-256 Hashing Algorithm</a>. This
            creates a fingerprint that is unique to that exact spin of the wheel and Secret, and you can use this later to
            verify the game. </p><img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-2.5058e88.jpg" alt="image-2">
        </li>
        <li>
          <h3 style="color: black;">We get you to have some input on the game.</h3>
          <p>We let you add an extra push to the wheel. You can select a number from 0 to 36 (or 37 for American roulette
            which has an extra tile) to push the wheel further along by that amount when you press spin. You can either
            pick your own number or use one chosen randomly for you.</p><img
            src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-3.ceb7797.jpg" alt="image-3">
        </li>
        <li>
          <h3 style="color: black;">You play the game!</h3>
          <p>Place your bets, start the game, and most of all have fun.</p>
        </li>
        <li>
          <h3 style="color: black;">We show you the actual data used to generate your game!</h3>
          <p>After the game is over, we show you the spin of the wheel (Result+Secret), the ‘fingerprint’ we showed you
            before the game (hash[Result+Secret]), the input you had on the game, and the final result. You can use our
            widget to verify that the spin matches the ‘fingerprint’ we gave you before the game, or you can use any third
            party hash calculator to verify it yourself!</p><img
            src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-4.1275716.jpg" alt="image-4">
        </li>
        <li>
          <h3 style="color: black;">Check the effect you had on the game!</h3>
          <p>You can even check the input you had on the game by comparing the spin (Result+Secret) and final result. Take
            the client seed and count that many tiles past the tile it was originally set to land on (found in
            Result+Secret) and you'll be at the same tile as the final result!</p>
          <p>For a walk-through of how to verify the game using an independent website</p>
          <p><a href="#" id="accordion" rel="nofollow">MANUAL VERIFICATION</a></p>
        </li>
        <li style="display: none;">
          <p>We have our own verification widget on the website, but what good is that if you want to make sure we are
            playing fair? You can check the hash we give you and the randomised spin and secret seed match up. It’s really
            quick and simple to do!</p>
          <p>Find a trusted third party hash calculator. We suggest <a href="https://www.quickhash-gui.org/"
              id="quickhash" rel="nofollow">quickhash.com</a>, but there are many others.<br>Copy the Result+Secret field
            from the game and paste the data into the input box on the third party calculator.<br>Select the SHA-256 as
            your algorithm and press generate.<br>The hash that is generate should exactly match the hash we presented you
            at the beginning of the game!</p>
          <p>This proves that the initial spin shown to you in a form of fingerprint was not changed in any way during the
            game play when you placed your bets or after you set your number in order to effect the game.</p>
        </li>
      </ol>
      <h2 style="color: black;">Provably Fair Pocket Dice</h2>
      <p>To achieve Provably Fair Gaming we utilise strong cryptographic algorithms such as <a
          href="https://en.wikipedia.org/wiki/SHA-2" id="sha" rel="nofollow">SHA-256 Hashing Algorithm</a>, <a
          href="https://en.wikipedia.org/wiki/Mersenne_Twister" id="twister" rel="nofollow">Mersenne Twister</a> and <a
          href="https://en.wikipedia.org/wiki/Fisher-Yates_shuffle" id="shuffle" rel="nofollow">Fisher-Yates shuffle</a>.
        These ensure the game cannot be tampered with.</p>
      <h2 style="color: black;">Technical Implementation</h2>
      <p>We generate 30 initial random numbers ranging from 1 to 6.<br>We generate random server seed.<br>The initial
        numbers are hashed using hash("sha256", json_encode($initial_numbers) . $server_seed). The resulting hash is made
        public.<br>When you start a game, we use javascript in your browser to create a client seed.<br>The initial
        numbers are shuffled calling Fisher-Yates shuffle with client seed.</p>
      <h3 style="color: black;">Code used for hashing initial numbers:</h3>
      <code><span class="comment">/**<br>* Calculates hash of array with server seed<br>* <span class="string">@param</span> array $initialNumbers<br>* <span class="string">@param</span> string $serverSeed<br>* <span class="string">@return</span> string<br>*/</span><br><span class="keyword">function</span> <span class="func">hashInitialNumbers</span>(array <span class="var">$initialNumbers</span>, <span class="var">$serverSeed</span>)<br>{<br> <span class="keyword">return</span> hash(<span class="string">'sha256'</span>, json_encode(<span class="var">$initialNumbers</span>) . <span class="var">$serverSeed</span>);<br>}</code>
      <h3 style="color: black;">Code used for shuffling:</h3>
      <code><span class="comment">/**<br>* Shuffles array with client seed<br>* <span class="string">@param</span> array $items<br>* <span class="string">@param</span> string $seed<br>* <span class="string">@return</span> array shuffled array<br>*/</span><br><span class="keyword">function</span> <span class="func">fisherYatesShuffle</span>(array <span class="var">$items</span>, <span class="var">$seed</span>)<br>{<br> <span class="var">$mt_seed</span> = intval(substr(hash(<span class="string">'sha256'</span>, <span class="var">$seed</span>), -<span class="num">8</span>), <span class="num">16</span>);<br> mt_srand(<span class="var">$mt_seed</span>);<br> <span class="var">$count</span> = count(<span class="var">$items</span>);<br> <span class="keyword">for</span> (<span class="var">$i</span> = <span class="var">$count</span> - <span class="num">1</span>; <span class="var">$i</span> &gt; <span class="num">0</span>; <span class="var">$i</span>--)<br> {<br>  <span class="var">$j</span> = mt_rand(<span class="num">0</span>, <span class="var">$i</span>);<br>  <span class="var">$tmp</span> = <span class="var">$items</span>[<span class="var">$i</span>];<br>  <span class="var">$items</span>[<span class="var">$i</span>] = <span class="var">$items</span>[<span class="var">$j</span>];<br>  <span class="var">$items</span>[<span class="var">$j</span>] = <span class="var">$tmp</span>;<br> }<br> <span class="keyword">return</span> <span class="var">$items</span>;<br>}<br><br><span class="var">$finalShuffle</span> = fisherYatesShuffle(<span class="var">$initialNumbers</span>, <span class="var">$clientSeed</span>);<br><span class="var">$result</span> = array_slice(<span class="var">$finalShuffle</span>, <span class="num">0</span>, <span class="num">2</span>);</code>
      <h2 style="color: black;">Provably Fair Cards</h2>
      <ol>
        <li>
          <h3>We generate a shuffled deck using modern shuffling techniques, as well as another number we call Secret.
          </h3>
          <p>Secret is a number generated by the server and used for the hash (explained later). To generate the shuffled
            deck we use a method known as <a href="https://en.wikipedia.org/wiki/Fisher-Yates_shuffle" id="shuffle"
              rel="nofollow">Fisher-Yates shuffle</a> which is similar to picking a number out of a hat. To make sure the
            numbers picked are completely random, we use the <a href="https://en.wikipedia.org/wiki/Mersenne_Twister"
              id="twister" rel="nofollow">Mersenne Twister</a> algorithm to generate random numbers for the Fisher Yate's
            Shuffle to use. This is considered the top standard for shuffling techniques. We call the shuffled deck
            'Result'.</p><img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-5.545774b.jpg" alt="image-5">
        </li>
        <li>
          <h3>We show you a 'fingerprint' of the shuffled deck and the 'Secret'.</h3>
          <p>We call this Hash (Result+Secret). We create this unique finger print by hashing the data with the <a
              href="https://en.wikipedia.org/wiki/SHA-2" id="sha" rel="nofollow">SHA-256 Hashing Algorithm</a>. This
            creates a fingerprint that is unique to that exact deck, and you can use this later to verify the game.</p>
          <img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-6.648e2d0.jpg" alt="image-6">
        </li>
        <li>
          <h3 style="color: black;">We get you to have some input on the game.</h3>
          <p>For card games, we let you cut the deck by picking a location to cut the deck. This is represented by a
            number called the client seed. We take the number you choose and use it to effect the outcome of the game.
            This ensures there is no way we could know the outcome of the game until the point it starts. So if for a game
            of poker you pick 24 as your client seed, we will cut the deck 24 cards from the top before dealing the cards
            and starting the game.</p><img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-7.40262c8.jpg"
            alt="image-7">
        </li>
        <li>
          <h3 style="color: black;">You play the game!</h3>
          <p>Place your bets, start the game, and most of all have fun.</p>
        </li>
        <li>
          <h3 style="color: black;">We show you the actual data used to generate your game!</h3>
          <p>After the game is over, we show you the full shuffled deck (Result+Secret), the ‘fingerprint’ we showed you
            before the game (hash[Result+Secret]), the input you had on the game (Client Seed), and the Final Result. You
            can use our widget to verify that the spin matches the ‘fingerprint’ we gave you before the game, or you can
            use any third party hash calculator to verify it yourself!</p><img
            src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-8.3c779ba.jpg" alt="image-8">
        </li>
        <li>
          <h3 style="color: black;">Check the effect you had on the game!</h3>
          <p>You can even check the input you had on the game by comparing the fully shuffled deck (Result+Secret) and the
            deck after it was cut (Final Result). You will find that the values are the same deck, but one of them has
            been 'cut' at the position you chose!</p>
          <p>For a walk-through of how to verify the game using an independent website</p>
          <p><a href="#" id="accordion" rel="nofollow">MANUAL VERIFICATION</a></p>
        </li>
        <li style="display: none;">
          <p>We have our own verification widget on the website, but what good is that if you want to make sure we are
            playing fair? You can check the hash we give you and the randomised spin and secret seed match up. It’s really
            quick and simple to do!</p>
          <p>Find a trusted third party hash calculator. We suggest <a href="https://www.quickhash-gui.org/"
              id="quickhash" rel="nofollow">quickhash.com</a>, but there are many others.<br>Copy the Result+Secret field
            from the game and paste the data into the input box on the third party calculator.<br>Select the SHA-256 as
            your algorithm and press generate.<br>The hash that is generate should exactly match the hash we presented you
            at the beginning of the game!</p>
          <p>This proves that the initial spin shown to you in a form of fingerprint was not changed in any way during the
            game play when you placed your bets or after you set your number in order to effect the game.</p>
        </li>
      </ol>
      <h2>Provably Fair Slots</h2>
      <ol>
        <li>
          <h3 style="color: black;">We use a computer model for the Slot Reels and decide on a random spin to apply to each one.</h3>
          <p>We model each reel as a list of the tiles in the order they appear and then generate a random location for
            each reel to stop at. We use the best pseudorandom number generator out there, the <a
              href="https://en.wikipedia.org/wiki/Mersenne_Twister" id="twister" rel="nofollow">Mersenne Twister</a>
            algorithm, to generate a random number for each slot. We call the spin 'Result'.</p>
        </li>
        <li>
          <h3 style="color: black;">We show you a 'fingerprint' of the spin and a random number we call 'Secret'.</h3>
          <p>We call this Hash (Result+Secret). We generate a random number called 'secret' and use this to create a
            fingerprint of the data to model the game. This ensures that you cannot figure out the final result of the
            spin from just the fingerprint. We create this unique finger print by hashing the data with the <a
              href="https://en.wikipedia.org/wiki/SHA-2" id="sha" rel="nofollow">SHA-256 Hashing Algorithm</a>. This
            creates a fingerprint that is unique to that exact spin of the reel and Secret seed, and you can use this
            later to verify the game.</p><img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-9.62acd81.jpg"
            alt="image-9">
        </li>
        <li>
          <h3 style="color: black;">We get you to have some input on the game.</h3>
          <p>We let you add an extra push to each of the reels. You can select a number from 0 to 9 for each reel to push
            that reel further along by that amount. You can either pick your own number or use one chosen randomly for
            you.</p><img src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-10.3d80574.jpg" alt="image-10">
        </li>
        <li>
          <h3 style="color: black;">You play the game!</h3>
          <p>Place your bets, start the game, and most of all have fun.</p>
        </li>
        <li>
          <h3 style="color: black;">We show you the actual data used to generate your game!</h3>
          <p>After the game is over, we show you the spin of the reel (Result+Secret), the fingerprint
            (hash[Result+Secret]), the input you had on the game (client seed), and the final outcome of the game (Final
            Result). You can use our widget to verify that the spin matches the ‘fingerprint’ we gave you before the game,
            or you can use any third party hash calculator to verify it yourself!</p><img
            src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-11.ae4a532.jpg" alt="image-11">
        </li>
        <li>
          <h3 style="color: black;">Check the effect you had on the game!</h3>
          <p>You can check the input you had on the game by comparing the spin (Result+Secret) and Final Result. The input
            you had on the game, called the client seed, is a number where each digit represents a different reel. So, for
            a 5 reel slot machine it will be a 5 digit number. If your number was 30700 then for your final result you
            will have the icon at the top of the first reel match the first icon in the fourth set shown in Result (the
            first set counts as 0). The second icon will the second icon listed in first set, third icon will be the third
            icon listed in the eighth set and so on. You can do the same for the second and third rows too!</p>
          <p>Example:</p>
          <p>Final Result</p>
          <code>{<br> "symbols": [<br>  [", <span class="green">"shield", "siren", "lightning", "medusa", "siren"</span>],<br>  [", <span class="blue">"thor", "neptune", "heracles", "shield", "artemis"</span>],<br>  [", <span class="red">"coin", "coin", "themis", "coin", "thor"</span>]<br> ],<br> "card": "6D"<br>}</code><mark>First
            Row – Green,&nbsp;</mark><mark>Second Row – Blue,&nbsp;</mark><mark>Third Row - Red</mark><img
            src="https://d3oqh5ecy4r3n8.cloudfront.net/img/image-12.1d6a8eb.jpg" alt="image-12">
          <p>Client Seed</p>
          <p>30700</p>
          <p>Result+Secret</p>
          <code>{<br> "symbols": [<br>  ["siren", <span class="green">"siren"</span>, "heracles", <span class="green">"medusa"</span>, <span class="green">"siren"</span>], (0)<br>  ["themis", <span class="blue">"neptune"</span>, "themis", <span class="blue">"shield"</span>, <span class="blue">"artemis"</span>], (1)<br>  ["heracles", <span class="red">"coin"</span>, "artemis", <span class="red">"coin"</span>, <span class="red">"thor"</span>], (2)<br>  [<span class="green">"shield"</span>, "heracles", "heracles", "medusa", "shield"], (3)<br>  [<span class="blue">"thor"</span>, "thor", "coin", "shield", "heracles"], (4)<br>  [<span class="red">"coin"</span>, "medusa", "themis", "artemis", "thor"], (5)<br>  ["lightning", "artemis", "ship", "ship", "medusa"], (6)<br>  ["minotaur", "shield", <span class="green">"lightning"</span>, "themis", "lightning"], (7)<br>  ["thor", "heracles", <span class="blue">"heracles"</span>, "coin", "shield"], (8)<br>  ["artemis", "thor", <span class="red">"themis"</span>, "artemis", "medusa"], (9)<br>  ["coin", "siren", "coin", "heracles", "artemis"], (10)<br>  ["ship", "heracles", "neptune", "medusa", "thor"] (11)<br> ],<br> "card": "6D",<br> "game": "slots:platinum_lightning",<br> "secret": "ad17e9bf57de88cd103569c6f84a1d63"<br>}</code>
          <p>For a walk-through of how to verify the game using an independent website</p>
          <p><a href="#" id="accordion" rel="nofollow">MANUAL VERIFICATION</a></p>
        </li>
        <li style="display: none;">
          <p>We have our own verification widget on the website, but what good is that if you want to make sure we are
            playing fair? You can check the hash we give you and the randomised spin and secret seed match up. It’s really
            quick and simple to do!</p>
          <p>Find a trusted third party hash calculator. We suggest <a href="https://www.quickhash-gui.org/"
              id="quickhash" rel="nofollow">quickhash.com</a>, but there are many others.<br>Copy the Result+Secret field
            from the game and paste the data into the input box on the third party calculator.<br>Select the SHA-256 as
            your algorithm and press generate.<br>The hash that is generate should exactly match the hash we presented you
            at the beginning of the game!</p>
          <p>This proves that the initial spin shown to you in a form of fingerprint was not changed in any way during the
            game play when you placed your bets or after you set your number in order to effect the game.</p>
        </li>
      </ol>
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'ProvabilityExplanation',
  data() {
    return {

    };
  },
  components: {
    footerBottom
  },
  methods: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.basic-page {
  --base-color: #d3d7db;
  --highlight-color: #fff;
  color: #d3d7db;
  color: var(--base-color);
  font-size: 18px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 930px;
  padding: 60px 15px;
  width: 100%
}

@media(max-width:1600px) {
  .basic-page {
    font-size: 16px;
    padding-bottom: 40px;
    padding-top: 40px
  }
}

@media(max-width:768px) {
  .basic-page {
    font-size: 14px;
    padding-bottom: 30px;
    padding-top: 30px
  }
}

.basic-page h1,
.basic-page h2,
.basic-page h3 {
  color: var(--highlight-color);
  line-height: 1.2;
  margin: 60px 0 30px
}

@media(max-width:1600px) {

  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 40px 0 25px
  }
}

@media(max-width:768px) {

  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 30px 0 20px
  }
}

.basic-page h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 72px;
  font-weight: 900;
  margin: 0 0 60px
}

@media(max-width:1600px) {
  .basic-page h1 {
    font-size: 48px;
    margin-bottom: 40px
  }
}

@media(max-width:768px) {
  .basic-page h1 {
    font-size: 32px;
    margin-bottom: 30px
  }
}

.basic-page h2 {
  font-size: 48px
}

@media(max-width:1600px) {
  .basic-page h2 {
    font-size: 32px
  }
}

@media(max-width:768px) {
  .basic-page h2 {
    font-size: 24px
  }
}

.basic-page h3 {
  font-size: 32px
}

@media(max-width:1600px) {
  .basic-page h3 {
    font-size: 24px
  }
}

@media(max-width:768px) {
  .basic-page h3 {
    font-size: 18px
  }
}

.basic-page a {
  color: var(--highlight-color);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline
}

.basic-page a:hover {
  -webkit-text-decoration: none;
  text-decoration: none
}

.basic-page ol {
  list-style: decimal inside
}

.basic-page ol>li {
  margin: 1em 0
}

.basic-page ol>li p:first-child {
  display: inline
}

.basic-page img {
  display: block;
  margin: 1.5em 0
}

.basic-page__video {
  margin-bottom: 2em;
  padding: 56.25% 0 0;
  position: relative
}

.basic-page__video iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.provability-explained-page {
  text-align: left;
}

::marker {
  display: none;
  font-size: 0;
}

.provability-explained-page.basic-page ol {
  list-style: none
}

.provability-explained-page.basic-page ol li {
  counter-increment: item
}

.provability-explained-page.basic-page ol li>h3:first-child:before {
  content: counter(item) ". "
}

.provability-explained-page mark {
  background-color: transparent;
  background-color: initial;
  display: inline-block;
  margin-bottom: 1em;
  margin-top: 1em
}

.provability-explained-page mark:first-of-type {
  color: #0dd182
}

.provability-explained-page mark:nth-of-type(2) {
  color: #598bff
}

.provability-explained-page mark:nth-of-type(3) {
  color: #fc4777
}

.provability-explained-page code {
  background-color: #2b2740;
  border-radius: 10px;
  color: #d3d7db;
  display: block;
  font-size: 14px;
  line-height: 1.6;
  padding: 2em 1em
}

.provability-explained-page code .comment {
  color: #998;
  font-style: italic
}

.provability-explained-page code .func {
  color: #900;
  font-weight: 700
}

.provability-explained-page code .num {
  color: #099
}

.provability-explained-page code .keyword {
  font-weight: 700
}

.provability-explained-page code .string {
  color: #d14
}

.provability-explained-page code .var {
  color: teal
}

.provability-explained-page code .blue {
  color: #598bff
}

.provability-explained-page code .green {
  color: #0dd182
}

.provability-explained-page code .red {
  color: #fc4777
}</style>
