<template>
  <main>
    <div class="basic-page general-terms is-page">
      <h1 style="color: black;">
        <!-- Terms &amp; Conditions -->
        {{footerData?.title}}
      </h1>
      <div
                        class="spinner"
                        style="margin-top: 150px"
                        v-if="loading"
                      ></div>
      <ol>
        <li  v-for="(footerData,index) in footerData?.qna"
                            :key="index">
          <h2 style="color: black;">{{footerData?.question}}</h2>
          <ol>
            <li>
              <p style="color: black;" class="flex-container" v-html="footerData?.answer"></p>
            </li>
          
            
          </ol>
        </li>

        <!-- <div class="general-terms__last-update">
          <p>Last updated: May 3rd, 2023</p> <a class="general-terms__download">
            Download PDF
          </a>
        </div> -->
      </ol>
    </div>


  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import footerBottom from '../parts/footer.vue'


export default {
  name: 'T&C',
  data() {
    return {
      footerData: [],
      loading: false,
      SITEAUTHKEY:""
    };
  },
  components: {
    footerBottom
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    async getFooterData() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_KEY}/api/navigation/get-all-navigation?name=Terms Conditions&site_auth_key=${this.SITEAUTHKEY}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        this.footerData = data?.data[0]?.data[0];
        // console.log(data?.data[0]?.data[0], "game in terms navigation");
      } catch (error) {
        console.error(error);
        // Handle error
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    const siteAuthKey=process.env.VUE_APP_API_SITE_AUTH_KEY
    this.SITEAUTHKEY=siteAuthKey
    this.getFooterData();

  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.basic-page {
  --base-color: #d3d7db;
  --highlight-color: #fff;
  color: #d3d7db;
  color: var(--base-color);
  font-size: 18px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 930px;
  padding: 60px 15px;
  width: 100%
}

@media(max-width:1600px) {
  .basic-page {
    font-size: 16px;
    padding-bottom: 40px;
    padding-top: 40px
  }
}

@media(max-width:768px) {
  .basic-page {
    font-size: 14px;
    padding-bottom: 30px;
    padding-top: 30px
  }
}

.basic-page h1,
.basic-page h2,
.basic-page h3 {
  color: var(--highlight-color);
  line-height: 1.2;
  margin: 60px 0 30px
}

@media(max-width:1600px) {

  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 40px 0 25px
  }
}

@media(max-width:768px) {

  .basic-page h1,
  .basic-page h2,
  .basic-page h3 {
    margin: 30px 0 20px
  }
}

.basic-page h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 72px;
  font-weight: 900;
  margin: 0 0 60px
}

@media(max-width:1600px) {
  .basic-page h1 {
    font-size: 48px;
    margin-bottom: 40px
  }
}

@media(max-width:768px) {
  .basic-page h1 {
    font-size: 32px;
    margin-bottom: 30px
  }
}

.basic-page h2 {
  font-size: 48px
}

@media(max-width:1600px) {
  .basic-page h2 {
    font-size: 32px
  }
}

@media(max-width:768px) {
  .basic-page h2 {
    font-size: 24px
  }
}

.basic-page h3 {
  font-size: 32px
}

@media(max-width:1600px) {
  .basic-page h3 {
    font-size: 24px
  }
}

@media(max-width:768px) {
  .basic-page h3 {
    font-size: 18px
  }
}

.basic-page a {
  color: var(--highlight-color);
  cursor: pointer;
  -webkit-text-decoration: underline;
  text-decoration: underline
}

.basic-page a:hover {
  -webkit-text-decoration: none;
  text-decoration: none
}

.basic-page ol {
  list-style: decimal inside
}

.basic-page ol>li {
  margin: 1em 0
}

.basic-page ol>li::marker {
  color: var(--highlight-color);
  font-weight: 700
}

.basic-page ol>li p:first-child {
  display: inline
}

.basic-page img {
  display: block;
  margin: 1.5em 0
}

.basic-page__video {
  margin-bottom: 2em;
  padding: 56.25% 0 0;
  position: relative
}

.basic-page__video iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%
}

.general-terms.basic-page ol {
  counter-reset: item;
  list-style: none
}

.general-terms.basic-page ol li {
  counter-increment: item
}

.general-terms.basic-page ol li>h2:first-child:before,
.general-terms.basic-page ol li>h3:first-child:before,
.general-terms.basic-page ol li>p:first-child:before {
  color: var(--highlight-color);
  content: counters(item, ".") ". ";
  font-weight: 700
}

.general-terms.is-in-pdf {
  --base-color: #161421;
  --highlight-color: #161421;
  font-size: 12px;
  padding: 20px;
  width: 590px
}

.general-terms.is-in-pdf h1 {
  font-size: 20px;
  margin-top: 0
}

.general-terms.is-in-pdf h1,
.general-terms.is-in-pdf h2 {
  font-weight: 400;
  letter-spacing: .2px;
  text-transform: uppercase
}

.general-terms.is-in-pdf h2 {
  font-size: 14px
}

.general-terms.is-in-pdf li,
.general-terms.is-in-pdf p {
  margin-top: 0
}

.general-terms.is-in-pdf a {
  display: inline-block
}

.general-terms.is-in-pdf .general-terms__download,
.general-terms.is-in-pdf .general-terms__download-spiner {
  display: none
}

.general-terms__last-update {
  margin-top: 40px
}

.general-terms__download-spiner {
  align-items: center;
  display: flex;
  pointer-events: none
}

.general-terms__download-spiner .c-spinner {
  border-color: #d3d7db;
  margin-right: 5px
}
.general-terms{
  text-align: left;
}
</style>