<template>
    <div class="game-list-container" style="z-index: 110;background-color: white;">
      <div class="drop-cn"></div>
      <div style="background-color: #3B3955;">
        <div @click="showDropdown = true" class="c-search-content-self">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="white"
              d="M22.49 21.07l-5.63-5.63A9.94 9.94 0 0 0 20 10c0-5.52-4.48-10-10-10S0 4.48 0 10s4.48 10 10 10c2.3 0 4.41-.77 6.13-2.07l5.63 5.63c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41zM10 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
            />
          </svg>
  
          <input
            id="gameSearchInput"
            placeholder="Find game or game studio (try roulette, book...)"
            autocomplete="off"
            class="c-search-input-self"
            style="color: white; ::placeholder { color: white; }"
            v-model="searchQuery1"
            @input="handleInput"
          />
          <span v-if="showDropdown" @click="showDropdown = false" class="search-cross">x</span>

        </div>
      </div>
      <div class="search-header" 
      style="background-color: white;"
      
      >
        <h2 style="color: black;">Search Results ({{ filteredResults.length+filteredMoreResults.length }})</h2>
        <div style="color: black;" class="filter-options" v-if="gameCategory.length>0">
        <span 
          v-for="category in gameCategory" 
          :key="category"
          @click="applyFilter(category?.original_name)"
          :class="{ active: isActiveCategory(category?.original_name) }"
        >
          {{ category?.original_name }}
        </span>
      </div>
      </div>
    </div>
  
    <div 
    
    class="loader-parent" style="display: flex; width: 100%; margin: auto; height: 100%;background-color:white ;">
      <div v-if="loading"  class="spinner-box">
        <div
        class="spinner"
        style="color: white; display: flex; margin: auto; margin-top: 50px;"
        >
    </div>
      </div>
       
      <div  
      style="background-color: white;"
      
      v-else-if="filteredResults.length === 0 && filteredMoreResults.length === 0"   class="no-search-data">
        <p style="font-weight: bold; font-size: 20px;">Whoops! There's no search results for '{{ searchQuery }}'</p>
        <p style="margin-top: -20px;">Try different keywords. Try using roulette, dragon, book...</p>
      </div>
    </div>
  
    <div
      v-if="!loading"
      class="games-grid"
      style="background-color: white;"
      :style="{ height: (filteredResults && filteredResults.length > 20) || (filteredMoreResults && filteredMoreResults.length > 20) ? '68vh' : 'auto' }"

    >
      <div
        v-for="game in filteredResults"
        :key="game._id.$oid"
        class="game-card"
        style="background-color: white;"
        @click="handleLoginSeemlessGame(game.gameProviderId, game.gameID, 'player')"
      >
        <div class="game-image">
          <img
            class="img"
            :src="game.gameInfos[0].gameIconUrl"
            style="object-fit: cover"
            alt="Game Image"
          />
        </div>
        <div class="game-name">{{ game.name }}</div>
        <div v-if="game.price" class="game-price">{{ game.price }}</div>
      </div>
      <a
        v-for="game in filteredMoreResults"
        :key="game._id.$oid"
        style="background-color: white;"
        class="game-card"
        :href="`/game/exclusive-casino-game/${encodeData(game.provider_id)}/${encodeData(game.game_id)}/${encodeData(game?.api_provider_name)}?mode=player`"
      >
        <div class="game-image">
          <img
            class="img"
            :src="game?.image_url"
            style="object-fit: cover"
            alt="Game Image"
          />
        </div>
      
      </a>
    </div>
  </template>
  
  <script>
import { encodeData } from "@/utils/utils";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
  export default {
    
    props: {
      searchQuery: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        searchResults: [],
        searchMoreResults:[],
        filteredResults: [], // Array to hold filtered search results
        filteredMoreResults:[],
        loading: false,
        showDropdown: true,
        providers: [],
        gameCategory: [],
        searchQuery1: "",
        selectedCategory: "All Games", // Track the selected category
        SITEAUTHKEY: "",
        debouncedTimer: null, // Debounce timer for search input
      };
    },
    watch: {
      searchQuery: {
        handler(newValue) {
          clearTimeout(this.debounceTimeout);
          this.debounceTimeout = setTimeout(() => {
            this.fetchAllGame(newValue);
            this.fetchMoreGame(newValue)
          }, 300);
        },
        immediate: true,
      },
    },
    mounted() {
      const siteAuthKey = process.env.VUE_APP_API_SITE_AUTH_KEY;
      this.SITEAUTHKEY = siteAuthKey;
      this.getCatgoryList()
    },
    created() {
    this.getCatgoryList();
  },
    computed: {
    activeCategories() {
      return this.gameCategory.filter(category => category.status);
    }
  },
    methods: {
      encodeData,
      handleInput() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          this.showDropdown = true;
          this.fetchAllGame();
          this.fetchMoreGame()
        }, 800);
      },
      isActiveCategory(category) {
      return this.selectedCategory === category;
    },
      applyFilter(category) {
        this.selectedCategory = category; // Update the selected category
        this.filterResults(); // Filter results based on the selected category
      },
      filterResults() {
        console.log(this.filteredResults,this.filteredMoreResults,"filteredResults")
        if (this.selectedCategory === "All Games") {

          this.filteredResults = this.searchResults; // Show all results if "All" is selected
          this.filteredMoreResults=this.searchMoreResults
        } else {
          this.filteredMoreResults = this.searchMoreResults.filter(game => {
            const categories = game.game_category || []; // Assuming `categories` is an array in each game object
            return categories.some(cat => cat.toLowerCase() === this.selectedCategory.toLowerCase());
          });
          this.filteredResults = this.searchResults.filter(game => {
            const categories = game.category || []; // Assuming `categories` is an array in each game object
            return categories.some(cat => cat.toLowerCase() === this.selectedCategory.toLowerCase());
          });

          
        }
      },
      async fetchAllGame() {
        try {
          this.loading = true;
          let url = `${process.env.VUE_APP_API_KEY}/api/game/get-all-game?status=true&page=1&limit=1000&site_auth_key=${this.SITEAUTHKEY}`;
          if (this.searchQuery) {
            url += `&search=${this.searchQuery}`;
          } else if (this.searchQuery1) {
            url += `&search=${this.searchQuery1}`;
          }
          const headers = {
            "Content-Type": "application/json",
          };
          const response = await fetch(url, {
            method: "GET",
            headers,
          });
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();
          console.log(data?.data, "csasd");
  
          this.searchResults = data.data;
          this.filterResults(); // Filter results after fetching the data
  
        } catch (error) {
          this.error = "Error fetching data: " + error.message;
          console.error("Error fetching data:", error);
        } finally {
          this.loading = false;
        }
      },
      async fetchMoreGame() {
      try {
        let url = `${process.env.VUE_APP_API_KEY}/api/secondary-game/get-game?limit=5000s0&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`
        if (this.searchQuery) {
            url += `&search=${this.searchQuery}`;
          } else if (this.searchQuery1) {
            url += `&search=${this.searchQuery1}`;
          }
        const headers = {
          'Content-Type': 'application/json'
        };

        const response = await fetch(url, {
          method: 'GET',
          headers: headers,

        });
        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
       
        console.log(data?.data,"more game")
        this.searchMoreResults = data.data;
          this.filterResults()
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error gracefully
      }
    },

    async getCatgoryList() {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_API_KEY}/api/game-navigation/get-all-game-navigation?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
      {
        method: "GET",
      }
    );

    // Check if the request was successful
    if (!response.ok) {
      throw new Error("Something went wrong");
    }

    // Parse the response JSON
    const responseData = await response.json();
    
    // Sort the gameCategory alphabetically by original_name
    this.gameCategory = responseData?.data.sort((a, b) => 
      a.original_name.localeCompare(b.original_name)
    );

    console.log("getcat in search", this.gameCategory);

    // Save the sorted category list
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
},
      async handleLoginSeemlessGame(gpid, gameid, mode) {
        const token = localStorage.getItem("userToken");
        const username_token = localStorage.getItem("usernameToken");
        const username = localStorage.getItem("username");
        if ((!token || !username_token || !username) && mode === "player") {
          toast("Please login first.");
          return;
        }
        window.location.href = `/game/casino/${encodeData(gpid)}/${encodeData(gameid)}?mode=${mode}`;
      },
    },
  };
  </script>
  

<style scoped>
.game-list-container {
  padding: 20px;
  
  color: black;
 
}
.drop-cn {
  height: 0;

  width: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 10px solid #3B3955;
  position: relative;
  top: -28px;
  left:-9px;
  z-index:10000;
  transform: rotate(180deg); 
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  /* margin: 0 auto; */
}
.c-search-content-self {
  position: relative;
  display: flex;
  align-items: center;
  /* background-color: white; */
  padding: 6px;
  gap:2px;
  padding-left: 10px;
  padding-right:10px
}
.c-search-content-active-self{
  background-color: white;

}
.search-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.search-header > h2 {
  margin-top: 0px;
  font-size: 16px;
  font-weight: bold;
}
.filter-options{
display:flex;
/* justify-content:end; */
width:70%;
align-items: start;
justify-content:start;
overflow:scroll;
text-wrap:nowrap;

}
.filter-options::-webkit-scrollbar{
  display:none
}
 .filter-options span {
  margin-left: 20px;
  cursor: pointer;
  font-size: 14px;
  color: black;
  font-weight:bold;
  
  /* border-bottom: 1px solid white; */
} 
.filter-options span.active {
  color: #ffd700;;
  border-radius: 0px;
  border-bottom: 2px solid #ffd700;

}
.search-container-small{
}
.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  overflow: scroll;
  gap: 12px;
  padding: 15px;
  margin-top:-30px;
  
  
}
.loader-parent{
display: flex;  width:100%;margin:auto;height:100%
}
.game-card {
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.game-image {
  position: relative;
  overflow: hidden; /* Ensures that the image does not overflow the container */
  z-index: 1000;
  
  border-radius: 8px; /* Apply border-radius to the container */
}

.game-image img {
  width: 100%;
  height: 170px;
  border-radius: 8px; /* Apply border-radius to the image */
  transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

.game-image img:hover {
  transform: scale(1.1); /* Scale the image to 110% on hover */
}
.game-label {
  position: absolute;
  top: 8px;
  left: 8px;
  color: black;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.game-name {
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
}

.game-price {
  margin-top: 5px;
  background-color: #663399;
  color: black;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;

}
.c-search-input-self {
  width: 85%;
  color:black;
  height: 40px;
  font-size: 16px;
  border-top:#3B3955;
  border-right: #3B3955;
  outline: none;
  border-color:#3B3955 ;
  border-left: #3B3955;
    background-color:#3B3955;
  margin-left:10px;
  margin-right:10px
}

.search-cross{
  width:30px;
  height:30px;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:6px;
  background-color:#56546E;
  color:black;
  font-size:25px;
}
.no-search-data {
  color: black;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner-box{
    display: flex;
    align-items:center;
    justify-content:center;
    width:100%
}

@media (min-width: 1024px) {
    .c-search-content-self {
  display: none;
}
}

@media (max-width: 1024px) {
    .game-list-container {
  padding: 0px;
  color: black;
} 
.loader-parent{
display: flex;  width:100%;margin:auto;height:100vh
}
.games-grid{

    min-height:100vh;
  margin-top:0px;

    padding-bottom:200px
}
.drop-cn{
  display:none;
}
.no-search-data {
  color:black;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.search-header {
  display: flex;
  margin-top: 10px;
  padding: 10px;
  flex-direction:column;

  align-items: flex-start;
}
.search-header > h2 {

  font-size: 16px;
 
}
.filter-options{
    display:flex;
    overflow:scroll;
    width: 100%;
    gap: 7px;
}

.filter-options span {
  cursor: pointer;
  font-size: 14px;
    padding:8px 15px;
  color:black;
  border-bottom: none;
  border-radius:6px;
  font-weight: "800px";
  background-color:#56546E;
  text-wrap: nowrap;
  margin-left: 0px;
  margin-top: 0px;

  font-weight: bold;

}
.filter-options span.active {
  background-color: #ffd700;
  border-radius: 4px;
  border-bottom: none;
  color:black

}

.spinner-box{
    min-height: 100vh;
    display: flex;
    align-items:center;
    justify-content:center;
    width:100%
}
}
@media (max-width:600px){
  .games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  overflow: scroll;
  gap: 10px;
  padding: 15px;
  
}
.game-image img {
  width: 100%;
  height: 165px;
  border-radius: 8px;
}

}
</style>
