<template>
    <div class="top-winners">
          <h2 class="bs-c-homepage-v2-title" style="color: #2B2740 !important;"> 
            {{ t(`Top`) }} {{ t(`Winners`) }}
          </h2>
          <div class="top-winners__tabs" style="color: #2B2740 !important;">
  <button style="color: #2B2740 !important;" :class="{ 'is-active': activeTab === 'day' }" @click="setActiveTab('day')">
    Day
  </button>
  <button style="color: #2B2740 !important;" :class="{ 'is-active': activeTab === 'week' }" @click="setActiveTab('week')">
    Month
  </button>
  <button style="color: #2B2740 !important;" :class="{ 'is-active': activeTab === 'monthMultiplier' }" @click="setActiveTab('monthMultiplier')">
    Month. Multiplier
  </button>
</div>

<div class="top-winners__sections">
  <!-- Section for Day -->
  <section class="top-winners__section" :class="{ 'is-active': activeTab === 'day' }">
    <h3 style="color: #2B2740 !important;">{{ t(`Winners of the Day`) }}</h3>
    <div v-for="game in topBetDataToday" :key="game._id" class="top-winners__table-row">
      <a href="/slots/wild-bison-charge"><img src="../assets/img/all/tournaments.d9504bc.png" alt="Wild Bison Charge"></a>
      <div class="top-winners__column-game"><strong>{{ t(`Game`) }}</strong> <a href="/slots/wild-bison-charge" style="color: #2B2740 !important;">{{game?.Provider}}</a></div>
      <div style="color: #2B2740 !important;" class="top-winners__column-user"><strong>{{ t(`User`) }}</strong> <span>{{game?.Username.slice(0,4)}}****</span></div>
      <div style="color: #2B2740 !important;" class="top-winners__column-profit"><strong>{{ t(`Profit`) }} </strong> <span class="top-winners__winner-profit">
        {{ game?.WinLoss }} {{game?.Currency}}
        <span  class="top-winners__info-wrapper"><svg xmlns="http://www.w3.org/2000/svg" class="top-winners__info-icon icon sprite-icons">
          <use href="#" xlink:href="#"></use>
        </svg> <span class="top-winners__original-tooltip" style="color: #2B2740 !important;">20,703 EUR</span></span></span></div>
    </div>
  </section>

  <!-- Section for Month -->
  <section class="top-winners__section" :class="{ 'is-active': activeTab === 'week' }">
    <h3 style="color: #2B2740 !important;">{{ t(`Winners of the Month`) }}</h3>
    <div v-for="game in topBetDataWeek" :key="game._id" class="top-winners__table-row">
      <a href="/slots/wild-bison-charge"><img src="../assets/img/all/tournaments.d9504bc.png" alt="Wild Bison Charge"></a>
      <div class="top-winners__column-game"><strong>{{ t(`Game`) }}</strong> <a href="/slots/wild-bison-charge">{{game?.Provider}}</a></div>
      <div class="top-winners__column-user"><strong>{{ t(`User`) }}</strong> <span>{{game?.Username.slice(0,4)}}****</span></div>
      <div class="top-winners__column-profit"><strong>{{ t(`Profit`) }} </strong> <span class="top-winners__winner-profit">
        {{ game?.WinLoss }} {{game?.Currency}}
        <span class="top-winners__info-wrapper"><svg xmlns="http://www.w3.org/2000/svg" class="top-winners__info-icon icon sprite-icons">
          <use href="#" xlink:href="#"></use>
        </svg> <span class="top-winners__original-tooltip">20,703 EUR</span></span></span></div>
    </div>
  </section>

  <!-- Section for Month Multiplier -->
  <section class="top-winners__section" :class="{ 'is-active': activeTab === 'monthMultiplier' }">
    <h3 style="color: #2B2740 !important;">{{ t(`Top`) }} {{ t(`Multipliers`) }} {{ t(`of`) }} {{ t(`the`) }} {{ t(`Month`) }}</h3>
    <div v-for="game in topBetDataMonth" :key="game._id" class="top-winners__table-row" style="font-weight: 900;">
      <a href="/slots/wild-bison-charge"><img src="../assets/img/all/tournaments.d9504bc.png" alt="Wild Bison Charge"></a>
      <div class="top-winners__column-game"><strong>{{ t(`Game`) }}</strong> <a href="/slots/wild-bison-charge">{{game?.Provider}}</a></div>
      <div class="top-winners__column-user"><strong>{{ t(`User`) }}</strong> <span>{{game?.Username.slice(0,4)}}****</span></div>
      <div class="top-winners__column-profit"><strong>{{ t(`Profit`) }} </strong> <span class="top-winners__winner-profit">
        {{ game?.WinLoss }} {{game?.Currency}}
        <span class="top-winners__info-wrapper"><svg xmlns="http://www.w3.org/2000/svg" class="top-winners__info-icon icon sprite-icons">
          <use href="#" xlink:href="#"></use>
        </svg> <span class="top-winners__original-tooltip">20,703 EUR</span></span></span></div>
    </div>
  </section>
</div>
        </div> 
</template>
  
<script>

import footerBottom from '../parts/footer.vue'
 export default {
    name: 'TopBetHighest',
    data() {
        return {
            topBetDataToday:[],
            topBetDataWeek:[],
            topBetDataMonth:[],
            activeTab: 'day',


        };
    },
    components: {
        footerBottom
    },
    methods: {
    t(key) {
      return this.$t(key);

    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    async getTopBetData() {
      try {


        // Check if tokens exist

        // Make GET request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/casino-bet/get-top-highest-bet?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "GET",
          }
        );
        // Check if the request was successful
        if (!response.ok) {
          throw new Error("somthing went wrong");
        }

        // Parse the response JSON
        const responseData = await response.json();
        console.log("getcategiryda", responseData);
        console.log("getcategiryda", responseData?.data?.week);

        this.topBetDataToday = responseData?.data?.today;
        this.topBetDataWeek = responseData?.data?.week;
        this.topBetDataMonth = responseData?.data?.month;



        // Save the retrieved user data
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    },
    mounted() {
    this.getTopBetData()
  },


    
}
</script>
  
<style scoped>

</style>

  