<template>
  <main>
    <div>
      <div class="homepage-variation-2">
        <div id="index-banner" class="bs-c-index-banner-v2">
          <Carousel />
        </div>

        <div id="" 
        
        
        >
          <div class="game-container">
            <div class="">
              <section
                v-if="topGames.length > 0"
                class="c-games-section-top-games"
              >
                <div id="top-games"  >
                  <section
                    data-cy="cy-games-sections-wrapper is-games-skeleton"
                    class="games-sections-wrapper is-games-skeleton"
                  >
                    <div
                      data-cy="cy-games-sections-skeleton"
                      class="games-sections game-part-1"
                     
                    >
                      <div
                        data-cy="cy-games-sections__heading-skeleton"
                        class="games-sections__heading"
                        
                      >
                        <div
                          data-cy="cy-games-sections__heading-box-skeleton"
                          class="games-sections__heading-box"
                        >
                          <div
                            data-cy="cy-games-sections__title-skeleton"
                            class="games-sections__title"
                          >
                            <span style="color: #2B2740;">👍 {{t(`Top`) }} {{ t(`Games`) }}</span>
                          </div>
                          <button
                            @click="scrollLeft(0)"
                            :disabled="isAtStart"
                            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                          >
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                          </button>
                          <button
                            @click="scrollRight(0)"
                            :disabled="isAtEnd"
                            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                          >
                            <font-awesome-icon :icon="['fas', 'arrow-right']" />
                          </button>
                        </div>
                        <div
                          data-cy="cy-swiper-header__box"
                          class="c-swiper-header__box"
                        >
                          <router-link
                            to="/casino/top-rated-games/top"
                            class="c-button c-view-all color-light-purple size-m"
                          >
                            <span>{{ t(`View all`) }}</span>
                          </router-link>
                          <div class="c-swiper-navigation"></div>
                        </div>
                      </div>
                      <div >
                        <div
                          class="c-swiper-side-gradient c-swiper-side-placeholder is-visible"
                        ></div>
                        <div
                          id="games-swiper-12"
                          data-cy="games-swiper-12"
                          ref="swiperContainer"
                          class="c-swiper scroll-enabled"
                          @scroll="onScroll(0)"
                        >
                          <div
                            id="swiper-item-1"
                            data-cy="cy-swiper-item"
                            class="c-swiper-item image-container"
                          >
                            <div
                              data-cy="cy-swiper-item-games"
                              class="c-swiper-item-games image-box"
                              @mouseenter="showNavigation = true"
                              @mouseleave="showNavigation = false"
                            >
                              <div
                                v-for="game in topGames"
                                :key="game._id"
                                class="game-box"
                                style="display: flex"
                              >
                                <a
                                  :href="`/top-games/${game.gameProviderId}/${game.gameID}?mode=player`"
                                  class="c-game-container game-route"
                                >
                                  <!-- Game badges -->
                                  <div class="c-game-badges">
                                    <div class="c-game-badges-collection"></div>
                                    <span class="game-badge-separator"></span>
                                    <!-- Use first character of provider's name to construct image source -->
                                    <span
                                      class="game-badge-provider is-bagde-hover"
                                    >
                                      <span
                                        class="game-badge-provider__overlay"
                                      ></span>
                                      <img
                                        style="width: 10px; height: 10px"
                                        src="https://companieslogo.com/img/orig/EVO.ST-913bcbb0.png?t=1684219168"
                                        :alt="game.provider"
                                        class="game-badge-provider__icon"
                                      />
                                    </span>
                                  </div>
                                  <!-- Game box with image -->
                                  <div class="c-game-box" >
                                    <img
                                      :src="game.gameInfos[0].gameIconUrl"
                                      :alt="game.gameInfos[0].gameName"
                                      class="c-game-box__image image-casino "
                                    />
                                  </div>
                                </a>
                              </div>
                              <div
                                :class="{
                                  'show-navigation': showNavigation,
                                  'hide-navigation': !showNavigation,
                                }"
                                class="navigation-buttons"
                                style="
                                  position: absolute;
                                  top: 100px;
                                  right: 0px;
                                  z-index: 10;
                                "
                              >
                                <button
                                  style="
                                    margin-top: 5px;
                                    background-color: #6f6e74;
                                    opacity: 0.9;
                                  "
                                  @click="scrollLeft(0)"
                                  :disabled="isAtStart"
                                  class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                                >
                                  <font-awesome-icon
                                    :icon="['fas', 'arrow-left']"
                                  />
                                </button>
                                <button
                                  style="
                                    margin-top: 5px;
                                    background-color: #6f6e74;
                                    opacity: 0.9;
                                  "
                                  @click="scrollRight(0)"
                                  :disabled="isAtEnd"
                                  class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                                >
                                  <font-awesome-icon
                                    :icon="['fas', 'arrow-right']"
                                  />
                                </button>
                              </div>
                            </div>

                            <template v-if="topLoading">
                              <!-- Skeleton loading state -->
                              <div class="skeleton-box">
                                <div
                                  v-for="n in 7"
                                  :key="n"
                                  style="background-color: #2b2740"
                                  class="skeleton"
                                ></div>
                              </div>
                            </template>
                          </div>
                        </div>
                        <div
                          class="c-swiper-side-gradient c-swiper-side-navigation"
                        ></div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
              <div class="game-category-section"></div>
              <div v-for="category in activeCategories" :key="category._id">
                <GameCategory
                  :gameCategory="category.name.toLowerCase()"
                  :icon="category.icon"
                />
              </div>

              <section
                v-if="jackpotGame?.length > 0"
                class="c-games-section-new-games"
              >
                <div id="new-games">
                  <section
                    data-cy="cy-games-sections-wrapper  is-games-skeleton"
                    class="games-sections-wrapper is-games-skeleton"
                  >
                    <div
                      data-cy="cy-games-sections-skeleton"
                      class="games-sections"
                    >
                      <div
                        data-cy="cy-games-sections__heading-skeleton"
                        class="games-sections__heading"
                      >
                        <div
                          data-cy="cy-games-sections__heading-box-skeleton"
                          class="games-sections__heading-box"
                        >
                          <div
                            data-cy="cy-games-sections__title-skeleton"
                            class="games-sections__title"
                          >
                            <span style="color: #2B2740;">🚀 {{ t(`Jackpots`) }} {{ t(`Games`) }}</span>
                          </div>
                          <button
                            @click="scrollLeft(1)"
                            :disabled="isAtStart"
                            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                          >
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                          </button>
                          <button
                            @click="scrollRight(1)"
                            :disabled="isAtEnd"
                            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                          >
                            <font-awesome-icon :icon="['fas', 'arrow-right']" />
                          </button>
                        </div>
                        <div
                          data-cy="cy-swiper-header__box"
                          class="c-swiper-header__box"
                        >
                          <router-link
                            to="/game-category/207/jackpot"
                            class="c-button c-view-all color-light-purple size-m"
                          >
                            <span>{{ t(`View all`) }}</span>
                          </router-link>
                          <div class="c-swiper-navigation"></div>
                        </div>
                      </div>
                      <div class="c-swiper-container">
                        <div
                          class="c-swiper-side-gradient c-swiper-side-placeholder"
                        ></div>
                        <div
                          id="games-swiper-12"
                          ref="swiperContainer1"
                          data-cy="games-swiper-12"
                          class="c-swiper scroll-enabled"
                          @scroll="onScroll(1)"
                        >
                          <div
                            id="swiper-item-1"
                            data-cy="cy-swiper-item"
                            class="c-swiper-item image-container"
                          >
                            <div
                              @mouseenter="showNavigation1 = true"
                              @mouseleave="showNavigation1 = false"
                              data-cy="cy-swiper-item-games"
                              class="c-swiper-item-games image-box"
                            >
                              <div
                                v-for="game in jackpotGame"
                                :key="game._id"
                                class="game-box"
                                style="display: flex"
                              >
                                <router-link
                                  :to="`/top-games/${game.gameProviderId}/${game.gameID}?mode=player`"
                                  class="c-game-container game-route"
                                >
                                  <!-- Game badges -->
                                  <div class="c-game-badges">
                                    <div class="c-game-badges-collection"></div>
                                    <span class="game-badge-separator"></span>
                                    <!-- Use first character of provider's name to construct image source -->
                                    <span
                                      class="game-badge-provider is-bagde-hover"
                                    >
                                      <span
                                        class="game-badge-provider__overlay"
                                      ></span>
                                      <img
                                        style="width: 10px; height: 10px"
                                        src="https://companieslogo.com/img/orig/EVO.ST-913bcbb0.png?t=1684219168"
                                        :alt="game.provider"
                                        class="game-badge-provider__icon"
                                      />
                                    </span>
                                  </div>
                                  <!-- Game box with image -->
                                  <div class="c-game-box">
                                    <img
                                      :src="game.gameInfos[0].gameIconUrl"
                                      :alt="game.gameInfos[0].gameName"
                                      class="c-game-box__image image-casino"
                                    />
                                  </div>
                                </router-link>
                              </div>
                              <div
                                :class="{
                                  'show-navigation1': showNavigation1,
                                  'hide-navigation1': !showNavigation1,
                                }"
                                class="navigation-buttons"
                                style="
                                  position: absolute;
                                  top: 100px;
                                  right: 0px;
                                  z-index: 10;
                                "
                              >
                                <button
                                  style="
                                    margin-top: 5px;
                                    background-color: #6f6e74;
                                    opacity: 0.9;
                                  "
                                  @click="scrollLeft(1)"
                                  :disabled="isAtStart"
                                  class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                                >
                                  <font-awesome-icon
                                    :icon="['fas', 'arrow-left']"
                                  />
                                </button>
                                <button
                                  style="
                                    margin-top: 5px;
                                    background-color: #6f6e74;
                                    opacity: 0.9;
                                  "
                                  @click="scrollRight(1)"
                                  :disabled="isAtEnd"
                                  class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                                >
                                  <font-awesome-icon
                                    :icon="['fas', 'arrow-right']"
                                  />
                                </button>
                              </div>
                            </div>
                            <template v-if="jackpotLoading">
                              <!-- Skeleton loading state -->
                              <div class="skeleton-box">
                                <div
                                  v-for="n in 7"
                                  :key="n"
                                  style="background-color: #2b2740"
                                  class="skeleton"
                                ></div>
                              </div>
                            </template>
                          </div>
                        </div>
                        <div
                          class="c-swiper-side-gradient c-swiper-side-navigation"
                        >
                          <!---->
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>

              <section
                v-if="slotGame?.length > 0"
                class="c-games-section-new-games"
              >
                <div id="new-games">
                  <section
                    data-cy="cy-games-sections-wrapper is-games-skeleton"
                    class="games-sections-wrapper is-games-skeleton"
                  >
                    <div
                      data-cy="cy-games-sections-skeleton"
                      class="games-sections"
                    >
                      <div
                        data-cy="cy-games-sections__heading-skeleton"
                        class="games-sections__heading"
                      >
                        <div
                          data-cy="cy-games-sections__heading-box-skeleton"
                          class="games-sections__heading-box"
                        >
                          <div
                            data-cy="cy-games-sections__title-skeleton"
                            class="games-sections__title"
                          >
                            <span style="color: #2B2740;">🔥 {{ t(`Slots`) }} {{ t(`Games`) }}</span>
                          </div>
                          <button
                            @click="scrollLeft(2)"
                            :disabled="isAtStart"
                            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                          >
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                          </button>
                          <button
                            @click="scrollRight(2)"
                            :disabled="isAtEnd"
                            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                          >
                            <font-awesome-icon :icon="['fas', 'arrow-right']" />
                          </button>
                        </div>
                        <div
                          data-cy="cy-swiper-header__box"
                          class="c-swiper-header__box"
                        >
                          <router-link
                            to="/game-category/207/jackpot"
                            class="c-button c-view-all color-light-purple size-m"
                          >
                            <span>{{ t(`View all`) }}</span>
                          </router-link>
                          <div class="c-swiper-navigation"></div>
                        </div>
                      </div>
                      <div class="c-swiper-container">
                        <div
                          class="c-swiper-side-gradient c-swiper-side-placeholder"
                        ></div>
                        <div
                          id="games-swiper-12"
                          ref="swiperContainer2"
                          data-cy="games-swiper-12"
                          class="c-swiper scroll-enabled"
                          @scroll="onScroll(2)"
                        >
                          <div
                            id="swiper-item-1"
                            data-cy="cy-swiper-item"
                            class="c-swiper-item image-container"
                          >
                            <div
                              @mouseenter="showNavigation2 = true"
                              @mouseleave="showNavigation2 = false"
                              data-cy="cy-swiper-item-games"
                              class="c-swiper-item-games image-box"
                            >
                              <div
                                v-for="game in slotGame"
                                :key="game._id"
                                class="game-box"
                                style="display: flex"
                              >
                                <router-link
                                  :to="`/top-games/${game.gameProviderId}/${game.gameID}?mode=player`"
                                  class="c-game-container game-route"
                                >
                                  <!-- Game badges -->
                                  <div class="c-game-badges">
                                    <div class="c-game-badges-collection"></div>
                                    <span class="game-badge-separator"></span>
                                    <!-- Use first character of provider's name to construct image source -->
                                    <span
                                      class="game-badge-provider is-bagde-hover"
                                    >
                                      <span
                                        class="game-badge-provider__overlay"
                                      ></span>
                                      <img
                                        style="width: 10px; height: 10px"
                                        src="https://companieslogo.com/img/orig/EVO.ST-913bcbb0.png?t=1684219168"
                                        :alt="game.provider"
                                        class="game-badge-provider__icon"
                                      />
                                    </span>
                                  </div>
                                  <!-- Game box with image -->
                                  <div class="c-game-box">
                                    <img
                                      :src="game.gameInfos[0].gameIconUrl"
                                      :alt="game.gameInfos[0].gameName"
                                      class="c-game-box__image image-casino"
                                    />
                                  </div>
                                </router-link>
                              </div>
                              <div
                                :class="{
                                  'show-navigation1': showNavigation2,
                                  'hide-navigation1': !showNavigation2,
                                }"
                                class="navigation-buttons"
                                style="
                                  position: absolute;
                                  top: 100px;
                                  right: 0px;
                                  z-index: 10;
                                "
                              >
                                <button
                                  style="
                                    margin-top: 5px;
                                    background-color: #6f6e74;
                                    opacity: 0.9;
                                  "
                                  @click="scrollLeft(2)"
                                  :disabled="isAtStart"
                                  class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                                >
                                  <font-awesome-icon
                                    :icon="['fas', 'arrow-left']"
                                  />
                                </button>
                                <button
                                  style="
                                    margin-top: 5px;
                                    background-color: #6f6e74;
                                    opacity: 0.9;
                                  "
                                  @click="scrollRight(2)"
                                  :disabled="isAtEnd"
                                  class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                                >
                                  <font-awesome-icon
                                    :icon="['fas', 'arrow-right']"
                                  />
                                </button>
                              </div>
                            </div>
                            <template v-if="slotLoading">
                              <!-- Skeleton loading state -->
                              <div class="skeleton-box">
                                <div
                                  v-for="n in 7"
                                  :key="n"
                                  style="background-color: #2b2740"
                                  class="skeleton"
                                ></div>
                              </div>
                            </template>
                          </div>
                        </div>
                        <div
                          class="c-swiper-side-gradient c-swiper-side-navigation"
                        >
                          <!---->
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>

              <section class="c-games-section-live-casino">
                <div id="live-casino">
                  <section
                    data-cy="cy-games-sections-wrapper is-games-skeleton"
                    class="games-sections-wrapper is-games-skeleton"
                  >
                    <div
                      data-cy="cy-games-sections-skeleton"
                      class="games-sections"
                    >
                      <div
                        v-if="allProvidersDropdown?.length > 0"
                        id="providers-swiper"
                      >
                        <!---->
                        <section class="game-studio">
                          <div class="c-swiper-header">
                            <div class="c-swiper-header__box">
                              <router-link
                                to="/game-studios"
                                class="games-sections__title is-clickable"
                                style="color: #2B2740 !important;"

                                ><!---->
                                <span
                                style="color: #2B2740;"

                                  >🔥 {{ t(`Game Studios`) }}</span
                                ></router-link
                              >
                              <button
                                @click="scrollLeft(4)"
                                :disabled="isAtStart"
                                class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                              >
                                <font-awesome-icon
                                  :icon="['fas', 'arrow-left']"
                                />
                              </button>
                              <button
                                @click="scrollRight(4)"
                                :disabled="isAtEnd"
                                class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                              >
                                <font-awesome-icon
                                  :icon="['fas', 'arrow-right']"
                                />
                              </button>
                            </div>
                            <div class="c-swiper-header__box">
                              <router-link
                                to="/game-studios"
                                class="c-button c-view-all color-light-purple size-m"
                                ><!---->
                                <span>{{
                                  t(`View
                                  all`)
                                }}</span>
                                <!----></router-link
                              >
                            </div>
                          </div>
                          <div style="position: relative">
                            <div
                              @mouseenter="showNavigation4 = true"
                              @mouseleave="showNavigation4 = false"
                              id=""
                              class="provider-container"
                              ref="swiperContainer4"
                            >
                              <div
                                id=""
                                class="provider-box"
                                v-for="provider in allProvidersDropdown"
                                :key="provider._id"
                              >
                                <router-link
                                  style="text-decoration: none"
                                  :to="`/game-provider/${provider.gpName}/${provider.gpId}`"
                                >
                                  <div
                                    class="provider-text"
                                    style="text-decoration: none"
                                  >
                                    <img
                                      class="provider-img"
                                      :src="provider?.image_url"
                                      alt="jh"
                                    />
                                  </div>
                                </router-link>
                              </div>
                            </div>
                            <div
                              style="
                                position: absolute;
                                top: 18px;
                                right: -10px;
                                z-index: 100;
                              "
                            >
                              <button
                                style="
                                  margin-top: 5px;
                                  background-color: #6f6e74;
                                  opacity: 0.9;
                                "
                                @click="scrollLeft(4)"
                                :disabled="isAtStart"
                                class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                              >
                                <font-awesome-icon
                                  :icon="['fas', 'arrow-left']"
                                />
                              </button>
                              <button
                                style="
                                  margin-top: 5px;
                                  background-color: #6f6e74;
                                  opacity: 0.9;
                                "
                                @click="scrollRight(4)"
                                :disabled="isAtEnd"
                                class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                              >
                                <font-awesome-icon
                                  :icon="['fas', 'arrow-right']"
                                />
                              </button>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div
                        style="
                          display: flex;
                          flex-direction: column;
                          gap: 20px;
                          margin-top: 20px;
                        "
                      >
                     <ProviderMapping/>                        
                      </div>

                      <div
                        v-if="liveCasino?.length > 0"
                        data-cy="cy-games-sections__heading-skeleton"
                        class="games-sections__heading"
                        style="margin-top: 40px"
                      >
                        <div
                          data-cy="cy-games-sections__heading-box-skeleton"
                          class="games-sections__heading-box"
                        >
                          <div
                            data-cy="cy-games-sections__title-skeleton"
                            class="games-sections__title"
                          >
                            <span 
                            style="color: #2B2740 !important;"
                            
                            >♦️ {{ t(`Live`) }} {{ t(`Casino`) }}</span>
                          </div>
                          <button
                            @click="scrollLeft(3)"
                            :disabled="isAtStart"
                            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                          >
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                          </button>
                          <button
                            @click="scrollRight(3)"
                            :disabled="isAtEnd"
                            class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                          >
                            <font-awesome-icon :icon="['fas', 'arrow-right']" />
                          </button>
                        </div>
                        <div
                          data-cy="cy-swiper-header__box"
                          class="c-swiper-header__box"
                        >
                          <router-link
                            to="/game-category/100/live"
                            class="c-button c-view-all color-light-purple size-m"
                          >
                            <span  
                            
                            style="color: white !important;"
                            
                            >{{ t(`View all`) }}</span>
                          </router-link>
                          <div class="c-swiper-navigation"></div>
                        </div>
                      </div>
                      <div class="c-swiper-container">
                        
                        <div
                          id="games-swiper-top-live-casino-games"
                          data-cy="games-swiper-top-live-casino-games"
                          class="c-swiper scroll-enabled"
                          ref="swiperContainer3"
                          @scroll="onScroll(3)"
                        >
                          <div
                            id="swiper-item-1"
                            data-cy="cy-swiper-item"
                            class="c-swiper-item image-container"
                          >
                            <div
                              @mouseenter="showNavigation3 = true"
                              @mouseleave="showNavigation3 = false"
                              data-cy="cy-swiper-item-games"
                              class="c-swiper-item-games image-box"
                            >
                              <div
                                v-for="game in liveCasino"
                                :key="game._id"
                                class="game-box"
                                style="display: flex"
                              >
                                <router-link
                                  :to="`/top-games/${game.gameProviderId}/${game.gameID}?mode=player`"
                                  class="c-game-container game-route"
                                >
                                  <!-- Game badges -->
                                  <div class="c-game-badges">
                                    <div class="c-game-badges-collection"></div>
                                    <span class="game-badge-separator"></span>
                                    <!-- Use first character of provider's name to construct image source -->
                                    <span
                                      class="game-badge-provider is-bagde-hover"
                                    >
                                      <span
                                        class="game-badge-provider__overlay"
                                      ></span>
                                      <img
                                        style="width: 10px; height: 10px"
                                        src="https://companieslogo.com/img/orig/EVO.ST-913bcbb0.png?t=1684219168"
                                        :alt="game.provider"
                                        class="game-badge-provider__icon"
                                      />
                                    </span>
                                  </div>
                                  <!-- Game box with image -->
                                  <div class="c-game-box">
                                    <img
                                      :src="game.gameInfos[0].gameIconUrl"
                                      :alt="game.gameInfos[0].gameName"
                                      class="c-game-box__image image-casino"
                                    />
                                  </div>
                                </router-link>
                              </div>
                              <div
                                :class="{
                                  'show-navigation1': showNavigation3,
                                  'hide-navigation1': !showNavigation3,
                                }"
                                class="navigation-buttons"
                                style="
                                  position: absolute;
                                  top: 100px;
                                  right: 0px;
                                  z-index: 10;
                                "
                              >
                                <button
                                  style="
                                    margin-top: 5px;
                                    background-color: #6f6e74;
                                    opacity: 0.9;
                                  "
                                  @click="scrollLeft(3)"
                                  :disabled="isAtStart"
                                  class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                                >
                                  <font-awesome-icon
                                    :icon="['fas', 'arrow-left']"
                                  />
                                </button>
                                <button
                                  style="
                                    margin-top: 5px;
                                    background-color: #6f6e74;
                                    opacity: 0.9;
                                  "
                                  @click="scrollRight(3)"
                                  :disabled="isAtEnd"
                                  class="c-button c-swiper-navigation__arrow has-icon color-light-purple size-m"
                                >
                                  <font-awesome-icon
                                    :icon="['fas', 'arrow-right']"
                                  />
                                </button>
                              </div>
                            </div>
                            <template v-if="casinoLoading">
                              <!-- Skeleton loading state -->
                              <div class="skeleton-box">
                                <div
                                  v-for="n in 7"
                                  :key="n"
                                  style="background-color: #2b2740"
                                  class="skeleton"
                                ></div>
                              </div>
                            </template>
                          </div>
                          <!---->
                        </div>
                       
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
          </div>
        </div>

        <section >
          <section class="bs-c-total-jackpot is-jackpot-hidden"></section>
        </section>
        <div class="bonuses-v2" style="color: #2B2740 !important;">
          <h2 style="color: #2B2740 !important;" class="bonuses-v1__title bs-c-homepage-v2-title">
            {{ t(`If you like bonuses, you’ll love bitleon`) }}
          </h2>
          <ul class="bonuses-v2__list">
            <li class="bonuses-v2__list-item bonuses-v2__level-up">
              <img
                src="../assets/img/level-up.eee4620.png"
                width="198"
                height="278"
                alt="Level Up Rewards"
                class="bonuses-v2__list-item-img"
              />
              <span class="bonuses-v2__list-item-text" style="color: #2B2740 !important;">Level Up Rewards</span>
            </li>
            <li class="bonuses-v2__list-item bonuses-v2__tournaments">
              <img
                src="../assets/img/tournaments.d9504bc.png"
                width="292"
                height="282"
                alt="Tournaments"
                class="bonuses-v2__list-item-img"
              />
              <span style="color: #2B2740 !important;" class="bonuses-v2__list-item-text">Tournaments</span>
            </li>
            <li class="bonuses-v2__list-item bonuses-v2__mystery-prizes">
              <img
                src="../assets/img/mystery-prizes.e94cd78.png"
                width="288"
                height="306"
                alt="Mystery Prizes"
                class="bonuses-v2__list-item-img"
              />
              <span style="color: #2B2740 !important;" class="bonuses-v2__list-item-text">Mystery Prizes</span>
            </li>
            <li class="bonuses-v2__list-item bonuses-v2__vip-club">
              <img
                src="../assets/img/vip-club.395aa01.png"
                width="288"
                height="308"
                alt="VIP Club"
                class="bonuses-v2__list-item-img"
              />
              <span style="color: #2B2740 !important;" class="bonuses-v2__list-item-text">VIP Club</span>
            </li>
          </ul>
          <router-link style="color: #2B2740 !important;" to="/promotions" class="bonuses-v2__learn-more">
            {{ t(`Find`) }} {{ t(`Out`) }} {{ t(`More`) }}
          </router-link>
        </div>

        <div>
          <TopBetHighest />
        </div>
        <div v-if="!isAuth" style="margin-top: 20px" class="lets-start">
          <div class="lets-start__content">
            <strong class="lets-start__slogan">{{
              t(`Let get started`)
            }}</strong>
            <button
              @click="openModal4"
              class="bs-c-button-yellow lets-start__cta"
            >
              <strong>{{ t(`Sign`) }}{{ t(`Up`) }}</strong>
              <span>{{ t(`It only takes 1 minute`) }}</span>
            </button>
          </div>
        </div>
        <section
          style="margin-top: 50px"
          class="c-site-description is-homepage-variation"
        >
          <div class="c-site-description__section">
            <h1 style="text-align: start;color: #2B2740 !important;">
              {{ t(`Best Bitcoin Casino Experience`) }}
            </h1>
            <p>
              <span
              style="color: #2B2740 !important;"
                >Bitcoin is the foremost cryptocurrency, with hundreds of
                thousands of businesses from around the globe accepting it as a
                method of payment.</span
              >
              <a > Read more </a>
            </p>
          </div>
        </section>
      </div>
    </div>
    <div>
      <footerBottom />
    </div>
  </main>
</template>

<script>
import footerBottom from "../parts/footer.vue";
import ProviderMapping from "./clonegames/ProviderMapping.vue";
import GameCategory from "./clonegames/GameCategory.vue";
import TopBetHighest from "./TopBetHighest.vue";
import { inject } from "vue";
import Carousel from "./Carousel.vue";
import axios from "axios";
import { openModal1 } from "../modalState.js";

export default {
  name: "HomePage",
  setup() {
    const modalState = inject("modalState");
    const closeModal4 = inject("closeModal4");
    const openModal4 = inject("openModal4");

    return {
      modalState,
      openModal4,
      closeModal4,
    };
  },

  data() {
    return {
      topGames: [],
      topLoading: false,
      totalPages: "1",
      slotGame: [],
      slotLoading: false,

      showNavigation: false,
      showNavigation1: false,
      showNavigation2: false,
      showNavigation3: false,
      showNavigation4: false,
      gameCategory: [],

      jackpotGame: [],
      jackpotLoading: false,

      allProvidersDropdown: [],
      isAuth: false,

      liveCasino: [],
      casinoLoading: false,
      carouselDetails: [],
      isAtStart: true,
      isAtEnd: false,
    };
  },
  props: {
    msg: String,
  },
  components: {
    footerBottom,
    ProviderMapping,
    GameCategory,
    TopBetHighest,
    Carousel,
  },

  computed: {
    activeCategories() {
      return this.gameCategory.filter((category) => category.status);
    },
  },
  created() {
    this.getCatgoryList();
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    openModal() {
      openModal1();
    },
    scrollLeft(value) {
      if (value === 0) {
        const swiperContainer = this.$refs.swiperContainer;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: -600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      } else if (value === 1) {
        const swiperContainer = this.$refs.swiperContainer1;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: -600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      } else if (value === 2) {
        const swiperContainer = this.$refs.swiperContainer2;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: -600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      } else if (value === 3) {
        const swiperContainer = this.$refs.swiperContainer3;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: -600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      } else if (value === 4) {
        const swiperContainer = this.$refs.swiperContainer4;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: -600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      }
    },
    scrollRight(value) {
      if (value === 0) {
        const swiperContainer = this.$refs.swiperContainer;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: 600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      } else if (value === 1) {
        const swiperContainer = this.$refs.swiperContainer1;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: 600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      } else if (value === 2) {
        const swiperContainer = this.$refs.swiperContainer2;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: 600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      } else if (value === 3) {
        const swiperContainer = this.$refs.swiperContainer3;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: 600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      } else if (value === 4) {
        const swiperContainer = this.$refs.swiperContainer4;
        if (swiperContainer) {
          swiperContainer.scrollBy({
            top: 0,
            left: 600, // Adjust this value as needed
            behavior: "smooth",
          });
          this.updateButtonState(value);
        }
      }
    },
    onScroll(value) {
      this.updateButtonState(value);
    },
    updateButtonState(value) {
      if (value === 0) {
        const swiperContainer = this.$refs.swiperContainer;
        if (swiperContainer) {
          this.isAtStart = swiperContainer.scrollLeft === 1;
          this.isAtEnd =
            swiperContainer.scrollLeft + swiperContainer.clientWidth >=
            swiperContainer.scrollWidth;
        }
      } else if (value === 1) {
        const swiperContainer = this.$refs.swiperContainer1;
        if (swiperContainer) {
          this.isAtStart = swiperContainer.scrollLeft === 1;
          this.isAtEnd =
            swiperContainer.scrollLeft + swiperContainer.clientWidth >=
            swiperContainer.scrollWidth;
        }
      } else if (value === 2) {
        const swiperContainer = this.$refs.swiperContainer2;
        if (swiperContainer) {
          this.isAtStart = swiperContainer.scrollLeft === 1;
          this.isAtEnd =
            swiperContainer.scrollLeft + swiperContainer.clientWidth >=
            swiperContainer.scrollWidth;
        }
      } else if (value === 3) {
        const swiperContainer = this.$refs.swiperContainer3;
        if (swiperContainer) {
          this.isAtStart = swiperContainer.scrollLeft === 1;
          this.isAtEnd =
            swiperContainer.scrollLeft + swiperContainer.clientWidth >=
            swiperContainer.scrollWidth;
        }
      } else if (value === 4) {
        const swiperContainer = this.$refs.swiperContainer4;
        if (swiperContainer) {
          this.isAtStart = swiperContainer.scrollLeft === 1;
          this.isAtEnd =
            swiperContainer.scrollLeft + swiperContainer.clientWidth >=
            swiperContainer.scrollWidth;
        }
      }
    },
    async fetchTopGames() {
      // console.log("calling API");
      this.topLoading = true;
      try {
        const url = `${process.env.VUE_APP_API_KEY}/api/game/get-top-game?status=true&page=1&limit=100&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        const headers = {
          "Content-Type": "application/json",
        };

        const response = await fetch(url, {
          method: "GET",
          headers: headers,
        });
        if (response.status !== 200) {
          this.topLoading = false;

          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        this.topGames = data.data;
        this.topLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error gracefully
        this.topLoading = false;
      }
    },
    async fetchDataJackpot() {
      this.jackpotLoading = true;

      try {
        const url = `${process.env.VUE_APP_API_KEY}/api/game/get-game-by-game-type?status=all&page=1&limit=34&game_type=207&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;

        const headers = {
          "Content-Type": "application/json",
        };

        const response = await fetch(url, {
          method: "GET",
          headers: headers,
        });
        if (response.status !== 200) {
          this.jackpotLoading = false;

          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        this.jackpotGame = data.data;
        // this.allNewGames
        // console.log(data.data )
        this.jackpotLoading = false;
      } catch (error) {
        this.jackpotLoading = false;

        console.error("Error fetching data:", error);
        // Handle error gracefully
      }
    },
    async fetchDataSlot() {
      // console.log("calling API");
      this.slotLoading = true;

      try {
        const url = `${process.env.VUE_APP_API_KEY}/api/game/get-game-by-game-type?status=all&page=1&limit=34&game_type=201&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;

        const headers = {
          "Content-Type": "application/json",
        };

        const response = await fetch(url, {
          method: "GET",
          headers: headers,
        });
        if (response.status !== 200) {
          this.slotLoading = false;

          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        this.slotGame = data.data;
        // this.allNewGames
        // console.log(data.data )
        this.slotLoading = false;
      } catch (error) {
        this.slotLoading = false;

        console.error("Error fetching data:", error);
        // Handle error gracefully
      }
    },
    async fetchProviderList() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/casinoprovider/get-provider?limit=100&status=true&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const responseData = await response.json();
        // console.log("category in provids:", responseData);

        // Save the retrieved user data
        this.allProvidersDropdown = responseData.data;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async fetchLiveCasino() {
      // console.log("calling API");
      this.casinoLoading = true;

      try {
        const url = `${process.env.VUE_APP_API_KEY}/api/game/get-game-by-game-type?status=all&page=1&limit=34&game_type=100&site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;

        const headers = {
          "Content-Type": "application/json",
        };

        const response = await fetch(url, {
          method: "GET",
          headers: headers,
        });
        if (response.status !== 200) {
          this.casinoLoading = false;

          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        this.liveCasino = data.data;
        // this.allNewGames
        // console.log(data.data )
        this.casinoLoading = false;
      } catch (error) {
        this.casinoLoading = false;

        console.error("Error fetching data:", error);
        // Handle error gracefully
      }
    },
    async getCatgoryList() {
      try {
        // Retrieve tokens from localStorage
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        // Check if tokens exist

        // Make GET request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/game-navigation/get-all-game-navigation?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "GET",
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("somthing went wrong");
        }

        // Parse the response JSON
        const responseData = await response.json();
        console.log("getcategiryda", responseData);
        this.gameCategory = responseData?.data;

        // Save the retrieved user data
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async carouselData() {
      try {
        // Retrieve tokens from localStorage
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        // Check if tokens exist

        // Make GET request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/setting/get-setting/123123?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "GET",
          }
        );
        if (!response.ok) {
          throw new Error("somthing went wrong");
        }
        const responseData = await response.json();
        console.log("carosuelData", responseData);
        this.carouselDetails = responseData?.data?.carousels;

        // Save the retrieved user data
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
  },

  mounted() {
    this.fetchTopGames();
    this.fetchDataSlot();
    this.fetchDataJackpot();
    this.fetchProviderList();
    this.fetchLiveCasino();
    this.updateButtonState();
    this.getCatgoryList();
    this.carouselData();
    const userToken = localStorage.getItem("userToken");
    const usernameToken = localStorage.getItem("usernameToken");
    if (userToken && usernameToken) {
      this.isAuth = true;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.skeleton-box {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.skeleton {
  width: 180px;
  height: 230px;
  border-radius: 8px;
}

@media screen and (max-width: 1200px) {
  .skeleton-box {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .skeleton {
    width: 130px;
    height: 130px;
    border-radius: 8px;
  }
}

@media screen and (max-width: 500px) {
  .skeleton-box {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }

  .skeleton {
    width: 120px;
    height: 130px;
    border-radius: 8px;
  }
}

.navigation-buttons {
  transition: opacity 600ms ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.show-navigation {
  opacity: 0.8;
  visibility: visible;
}

.hide-navigation {
  opacity: 0;
  visibility: hidden;
}

.show-navigation1 {
  opacity: 0.8;
  visibility: visible;
}

.hide-navigation1 {
  opacity: 0;
  visibility: hidden;
}

.show-navigation4 {
  opacity: 0.8;
  visibility: visible;
}

.hide-navigation4 {
  opacity: 0;
  visibility: hidden;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.provider-container {
  display: flex;
  overflow-y: hidden;
  gap: 15px;
  padding-left: 0px;
  /* padding: 10px; */
  position: relative;
}
.jackpotgame {
}
.provider-container::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
}

.game-studio {
  margin: auto;
  margin-left:0px;
}
.game-part-1{
  margin-top:30px
}
.provider-text {
  width: 250px;
  padding: 20px;
  background-color: #272533;
  font-weight: bold;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.provider-box {
  /* margin-right: 16px; */
  border-radius: 8px;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 130px;
}

.provider-img {
  width: 100%;
  
  border-radius: 8px; /* Apply border-radius to the image */
  transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

.provider-img:hover {
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .c-swiper-wrapper {
    padding: 0 15px;
  }

  .game-studio {
    margin: auto;
    /* width:94%; */
  }
  .provider-text {
    width: 160px;
    padding: 20px;
    background-color: #272533;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

@media (max-width: 1024px) {
  .c-swiper-header {
    margin: 0 0 11px;
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px;
}

@media (max-width: 1024px) {
  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px;
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0;
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

@media (max-width: 1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px);
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%;
}

@media (max-width: 1024px) {
  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px;
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-swiper-item.is-placeholder {
  width: 6.65%;
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px;
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px;
}

@media (max-width: 1024px) {
  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px;
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px;
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px;
}

.game-category-section {
  margin-top: 40px;
}
@media (max-width: 1024px) {
  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px;
  }
  .game-category-section {
    margin-top: -40px;
  }
}

@media (max-width: 478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%;
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%;
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%;
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%;
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4;
}

@media (max-width: 1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px;
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease-in;
  width: 0.5%;
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1;
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all 0.25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%;
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none;
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity 0.25s ease-in;
}

.c-swiper-side-navigation
  .c-swiper-navigation
  .c-swiper-navigation__arrow:first-child {
  margin-top: 10px;
}

.c-swiper-container:hover
  .c-swiper-side-navigation
  .c-swiper-navigation
  .c-swiper-navigation__arrow {
  opacity: 1;
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto;
}

@media (max-width: 1024px) {
  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px);
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container
  .c-game-container:hover
  .c-game-badges
  .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  /* margin: 0 15px 0 0; */
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

@media (max-width: 700px) {
  .games-sections__title {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, 0.05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform 0.2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform 0.2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.game-container{
  padding-top:-80px

}


@media (max-width: 1024px) {
  .game-container{
  padding:0px;
  padding-top:45px;
}
.game-part-1{
  margin-top:-20px
}

}

</style>
